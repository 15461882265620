import React, { memo, useEffect, useState } from "react";
import SimpleTopBar from "components/SimpleTopBar/SimpleTopBar";
import { Container, Content, ActionsContainer } from "./styledComponents";
import { createStructuredSelector } from "reselect";
import { PageTitle } from "components/PageTitle/PageTitle";
import Button from "components/Button/Button";
import Table from "components/Table/Table";
import { useInjectReducer } from "utils/injectReducer";
import { useInjectSaga } from "utils/injectSaga";
import reducer from "./reducer";
import saga from "./saga";
import { connect } from "react-redux";
import { compose } from "redux";
import { makeSelectCompaniesData } from "./selectors";
import { loadCompanies, createCompany } from "containers/Companies/actions";
import NewItemModal from "components/NewItemModal";

const key = "companies";

export function CompaniesView({
  history,
  getCompanies,
  companies,
  newCompany
}) {
  useInjectReducer({ key, reducer });
  // @ts-ignore
  useInjectSaga({ key, saga });

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const toggleModal = () => {
    setModalIsVisible(!modalIsVisible);
  };
  const handleSubmit = name => {
    newCompany(name);
    setModalIsVisible(false);
  };
  return (
    <Container>
      <SimpleTopBar />
      <Content>
        <ActionsContainer>
          <PageTitle>COMPANIES</PageTitle>
          <Button onClick={toggleModal} data-cy="new-company">
            + Crear nueva
          </Button>
        </ActionsContainer>
        <Table
          onClickItem={id => history.push(`/companies/${id}`)}
          data={companies}
          data-cy="companies-table"
        />
      </Content>
      <NewItemModal
        title="Nueva compañia"
        label="Nombre"
        submitText="Crear"
        isVisible={modalIsVisible}
        toggleModal={toggleModal}
        onSubmit={handleSubmit}
        id="new-company"
      />
    </Container>
  );
}

const mapStateToProps = createStructuredSelector({
  companies: makeSelectCompaniesData()
});

export const mapDispatchToProps = {
  getCompanies: () => loadCompanies(),
  newCompany: name => createCompany(name)
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withConnect,
  memo
)(CompaniesView);

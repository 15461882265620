import React from "react";
import {
  Container,
  Title,
  Name,
  Avatar,
  TitleContainer,
  InfoContainer,
  Header,
  Sections,
  SectionsList,
  SectionsListItem
} from "./styledComponents";
import { Link } from "react-router-dom";
import avatarImg from "assets/images/avatar.png";
import BackIcon from "components/BackIcon/BackIcon";

export default function({ selectedTab, onChange, onGoBack, title = "" }) {
  return (
    <Container>
      <Header>
        <TitleContainer>
          <BackIcon onClick={onGoBack} />
          <Title>{title}</Title>
        </TitleContainer>
        <InfoContainer>
          <Name>Hi!</Name>
          <Link to="/">
            <Avatar src={avatarImg} alt="jaime" />
          </Link>
        </InfoContainer>
      </Header>
      <Sections>
        <SectionsList>
          <SectionsListItem
            className={
              selectedTab === "process" ? "sectionsListItem-active" : null
            }
            onClick={() => {
              if (selectedTab === "process") {
                return;
              }
              onChange("process");
            }}
          >
            PROCESOS
          </SectionsListItem>
          <SectionsListItem
            className={
              selectedTab === "defaultValues" ? "sectionsListItem-active" : null
            }
            onClick={() => {
              if (selectedTab === "defaultValues") {
                return;
              }
              onChange("defaultValues");
            }}
          >
            VALORES PREDETERMINADOS
          </SectionsListItem>
          <SectionsListItem
            className={
              selectedTab === "reports" ? "sectionsListItem-active" : null
            }
            onClick={() => {
              if (selectedTab === "reports") {
                return;
              }
              onChange("reports");
            }}
          >
            REPORTES
          </SectionsListItem>
        </SectionsList>
      </Sections>
    </Container>
  );
}

import React, { Fragment, useState } from "react";
import {
  ActionsContainer,
  ProcessItem,
  ProcessItemHeader,
  ProcessItemBody,
  ProcessItemTitle,
  AddProcessItemElement,
  AddProcessItemElementText,
  ProcessItemList,
  ProcessItemListElement,
  ProcessItemListElementText,
  ProcessItemListElementIcon,
  ActivityItemListElement,
  ActivityListIcon
} from "./styledComponents";
import Button from "components/Button/Button";
import AddIcon from "components/AddIcon/AddIcon";
import ArrowRight from "components/ArrowRight/ArrowRight";
import EditIcon from "components/EditIcon/EditIcon";
import NewItemModal from "components/NewItemModal";
import NewActivityModal from "components/NewActivityModal";
import filter from "lodash/filter";
import isEqual from "lodash/isEqual";
import { Column } from "rbx";

function ProcessTab({
  macros = [],
  processes = [],
  selectMacro,
  selectedMacro,
  newMacro,
  selectProcess,
  selectedProcess,
  newProcess,
  activities,
  newActivity,
  editActivity,
  onEdit,
  isEnable
}) {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const toggleModal = () => {
    setModalIsVisible(!modalIsVisible);
  };
  const handleSubmitMacro = name => {
    newMacro(name);
    setModalIsVisible(false);
  };
  const toogleProductivity = () => {
    onEdit({ enabled: !isEnable });
  };
  return (
    <Fragment>
      <ActionsContainer>
        <Button onClick={toogleProductivity}>
          {isEnable ? "DESACTIVAR" : "ACTIVAR"}
        </Button>
        <Button>Cargar Excel</Button>
      </ActionsContainer>
      <Column.Group>
        <Column>
          <ProcessItem>
            <ProcessItemHeader>
              <ProcessItemTitle>Macro procesos</ProcessItemTitle>
            </ProcessItemHeader>
            <ProcessItemBody>
              <AddProcessItemElement onClick={toggleModal}>
                <AddIcon />
                <AddProcessItemElementText>
                  Crear macro proceso
                </AddProcessItemElementText>
              </AddProcessItemElement>
              <ProcessItemList>
                {Object.values(macros).map(item => (
                  <ProcessItemListElement
                    key={item.id}
                    onClick={() => selectMacro(item.id)}
                    className={
                      item.id === selectedMacro
                        ? `ProcessItemListElement-active`
                        : undefined
                    }
                  >
                    <ProcessItemListElementText>
                      {item.description}
                    </ProcessItemListElementText>
                    <ProcessItemListElementIcon>
                      <ArrowRight />
                    </ProcessItemListElementIcon>
                  </ProcessItemListElement>
                ))}
              </ProcessItemList>
            </ProcessItemBody>
          </ProcessItem>
        </Column>
        <Column>
          <ProcessesBox
            selectedMacro={selectedMacro}
            selectedProcess={selectedProcess}
            updateProcess={selectProcess}
            newProcess={newProcess}
            processes={filter(
              processes,
              item => item.macro_process_id === selectedMacro
            )}
          />
        </Column>
        <Column>
          <ActivitiesBox
            selectedProcess={selectedProcess}
            activities={filter(
              activities,
              item => item.process_id === selectedProcess
            )}
            newActivity={newActivity}
            editActivity={editActivity}
          />
        </Column>
      </Column.Group>
      <NewItemModal
        title="Nuevo macro proceso"
        label="Descripción"
        submitText="Crear"
        isVisible={modalIsVisible}
        toggleModal={toggleModal}
        onSubmit={handleSubmitMacro}
      />
    </Fragment>
  );
}

function ProcessesBox({
  processes = [],
  selectedMacro,
  selectedProcess,
  updateProcess,
  newProcess
}) {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const toggleModal = () => {
    setModalIsVisible(!modalIsVisible);
  };
  const handleSubmitProcess = name => {
    newProcess(name);
    setModalIsVisible(false);
  };
  if (!selectedMacro) {
    return null;
  }
  return (
    <Fragment>
      <ProcessItem>
        <ProcessItemHeader>
          <ProcessItemTitle>Procesos</ProcessItemTitle>
        </ProcessItemHeader>
        <ProcessItemBody>
          <AddProcessItemElement onClick={toggleModal}>
            <AddIcon />
            <AddProcessItemElementText>Crear Proceso</AddProcessItemElementText>
          </AddProcessItemElement>
          <ProcessItemList>
            {processes.map(item => (
              <ProcessItemListElement
                key={item.id}
                onClick={() => updateProcess(item.id)}
                className={
                  item.id === selectedProcess
                    ? `ProcessItemListElement-active`
                    : undefined
                }
              >
                <ProcessItemListElementText>
                  {item.description}
                </ProcessItemListElementText>
                <ProcessItemListElementIcon>
                  <ArrowRight />
                </ProcessItemListElementIcon>
              </ProcessItemListElement>
            ))}
          </ProcessItemList>
        </ProcessItemBody>
      </ProcessItem>
      <NewItemModal
        title="Nuevo proceso"
        label="Descripción"
        submitText="Crear"
        isVisible={modalIsVisible}
        toggleModal={toggleModal}
        onSubmit={handleSubmitProcess}
      />
    </Fragment>
  );
}

function ActivitiesBox({
  selectedProcess,
  activities = [],
  newActivity,
  editActivity
}) {
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [activityDraft, setActivityDraft] = useState({});
  if (!selectedProcess) {
    return null;
  }
  const toggleModal = () => {
    if (modalIsVisible) {
      setActivityDraft({});
    }
    setModalIsVisible(!modalIsVisible);
  };
  const handleSubmitActivity = (description, action) => {
    if (isEqual(activityDraft, {})) {
      newActivity(selectedProcess, description, action);
    } else {
      editActivity(activityDraft.id, description, action);
    }
    setModalIsVisible(false);
  };
  const handleEditActivity = activity => {
    setActivityDraft(activity);
    toggleModal();
  };
  return (
    <Fragment>
      <ProcessItem className="ProcessItem-last">
        <ProcessItemHeader>
          <ProcessItemTitle>Actividades</ProcessItemTitle>
        </ProcessItemHeader>
        <ProcessItemBody>
          <AddProcessItemElement onClick={toggleModal}>
            <AddIcon />
            <AddProcessItemElementText>
              Crear Actividad
            </AddProcessItemElementText>
          </AddProcessItemElement>
          <ProcessItemList>
            {activities.map(item => (
              <ActivityItemListElement key={item.id}>
                <ProcessItemListElementText>
                  {item.description}
                </ProcessItemListElementText>
                <ActivityListIcon>
                  <EditIcon onClick={() => handleEditActivity(item)} />
                </ActivityListIcon>
              </ActivityItemListElement>
            ))}
          </ProcessItemList>
        </ProcessItemBody>
      </ProcessItem>
      <NewActivityModal
        title={
          isEqual(activityDraft, {})
            ? "Nueva activitidad"
            : "Editar activitidad"
        }
        submitText={isEqual(activityDraft, {}) ? "Crear" : "Editar"}
        activity={activityDraft}
        isVisible={modalIsVisible}
        toggleModal={toggleModal}
        onSubmit={handleSubmitActivity}
      />
    </Fragment>
  );
}

export default ProcessTab;

import styled from "styled-components";

export const ModalFormGroup = styled.div`
  width: 40em;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
`;

export const ModalLabel = styled.label`
  font-size: 1.08em;
  color: rgba(95, 97, 118, 0.51);
`;

export const ModalInput = styled.input`
  display: flex;
  border: 1px solid rgba(32, 41, 56, 0.5);
  color: #434452;
  font-size: 1.17em;
  font-weight: 600;
  border-radius: 0.22em;
  height: 2.3em;
  margin-top: 0.4em;
  padding-left: 1.22em;
  transition-duration: 0.3s;
  &:focus,
  &:active {
    outline: 0;
    border: 2px solid #ffa700;
  }
`;

export const ModalSubmit = styled.button`
  height: 3.21em;
  widows: 100%;
  margin-top: 2.85em;
  background-color: #ffa700;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.17em;
  border: none;
  border-radius: 0.357em;
  cursor: pointer;
  &:focus,
  &:active {
    outline: 0;
  }
  &:active {
    position: relative;
    top: 1px;
  }
`;

import React from "react";

export default ({
  width = 10.605,
  height = 8.955,
  color = "#ffa700",
  ...rest
}) => {
  return (
    <i {...rest}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      >
        <path
          d="M1595.436-8.879l-3.9-3.9a.5.5,0,0,1-.24-.425.5.5,0,0,1,.069-.253.5.5,0,0,1,.084-.112.5.5,0,0,1,.055-.047l3.929-3.93a.5.5,0,0,1,.7,0,.5.5,0,0,1,0,.7L1593-13.7h8.41a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5h-8.392l3.122,3.123a.5.5,0,0,1,0,.7.5.5,0,0,1-.351.145A.5.5,0,0,1,1595.436-8.879Z"
          transform="translate(1601.905 -8.734) rotate(180)"
          fill={color}
        />
      </svg>
    </i>
  );
};

import {
  LOAD_PRODUCTS,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_PRODUCT,
  LOAD_PRODUCT_SUCCESS,
  LOAD_PRODUCTS_ERROR,
  SET_CURRENT_PRODUCT,
  LOAD_PRODUCT_FAILURE,
  LOAD_MACRO_PROCESSES,
  LOAD_MACRO_PROCESSES_SUCCESS,
  LOAD_MACRO_PROCESSES_FAILURE,
  LOAD_PROCESSES,
  LOAD_PROCESSES_SUCCESS,
  LOAD_PROCESSES_FAILURE,
  LOAD_ACTIVITIES,
  LOAD_ACTIVITIES_SUCCESS,
  LOAD_ACTIVITIES_FAILURE,
  CREATE_MACRO_PROCESS,
  CREATE_MACRO_PROCESS_SUCCESS,
  CREATE_MACRO_PROCESS_FAILURE,
  CREATE_PROCESS,
  CREATE_PROCESS_SUCCESS,
  CREATE_PROCESS_FAILURE,
  CREATE_ACTIVITY,
  CREATE_ACTIVITY_SUCCESS,
  CREATE_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAILURE,
  GET_GENERIC_POSITIONS,
  GET_GENERIC_POSITIONS_SUCCESS,
  GET_GENERIC_POSITIONS_FAILURE,
  CREATE_GENERIC_POSITION,
  CREATE_GENERIC_POSITION_SUCCESS,
  CREATE_GENERIC_POSITION_FAILURE,
  GET_MANAGEMENT_POSITIONS,
  GET_MANAGEMENT_POSITIONS_SUCCESS,
  GET_MANAGEMENT_POSITIONS_FAILURE,
  GET_WORK_POSITIONS,
  GET_WORK_POSITIONS_SUCCESS,
  GET_WORK_POSITIONS_FAILURE,
  CREATE_MANAGEMENT_POSITION,
  CREATE_MANAGEMENT_POSITION_SUCCESS,
  CREATE_MANAGEMENT_POSITION_FAILURE,
  CREATE_WORK_POSITION,
  CREATE_WORK_POSITION_SUCCESS,
  CREATE_WORK_POSITION_FAILURE,
  EDIT_PRODUCTIVITY,
  EDIT_PRODUCTIVITY_SUCCESS,
  EDIT_PRODUCTIVITY_FAILURE
} from "./constants";

/**
 * Load the PRODUCTS, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_PRODUCTS
 */
export function loadProducts() {
  return {
    type: LOAD_PRODUCTS
  };
}

/**
 * Dispatched when the PRODUCTS are loaded by the request saga
 *
 * @param  {array} products The products data
 *
 * @return {object}      An action object with a type of LOAD_PRODUCTS_SUCCESS passing the repos
 */
export function getProductsSuccess(products) {
  return {
    type: LOAD_PRODUCTS_SUCCESS,
    payload: { data: products }
  };
}

/**
 * Dispatched when loading the COMPANIES fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_PRODUCTS_ERROR passing the error
 */
export function getProductsFailure(error) {
  return {
    type: LOAD_PRODUCTS_ERROR,
    error
  };
}

/**
 * Load the current PRODUCT, this action starts the request saga
 *
 * @param  {number} id The product id
 *
 * @return {object} An action object with a type of LOAD_COMPANIES
 */
export function getProduct(id) {
  return {
    type: LOAD_PRODUCT,
    payload: id
  };
}

/**
 * Dispatched when the PRODUCT details are loaded by the request saga
 *
 * @param  {object} product The product data
 *
 * @return {object}      An action object with a type of LOAD_PRODUCT_SUCCESS passing the repos
 */
export function getProductSuccess(product) {
  return {
    type: LOAD_PRODUCT_SUCCESS,
    payload: product
  };
}

/**
 * Dispatched when loading the COMPANIES fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_PRODUCT_FAILURE passing the error
 */
export function getProductFailure(error) {
  return {
    type: LOAD_PRODUCT_FAILURE,
    error
  };
}

/**
 * Dispatched when the COMPANY details are loaded by the request saga
 *
 * @param  {number} id The company data
 *
 * @return {object}      An action object with a type of LOAD_PRODUCT_SUCCESS passing the repos
 */
export function setCurrentProductId(id) {
  return {
    type: SET_CURRENT_PRODUCT,
    payload: { id }
  };
}

export function getMacroProcesses(productivity_id) {
  return {
    type: LOAD_MACRO_PROCESSES,
    payload: { productivity_id }
  };
}

export function getMacroProcessesSuccess(productivity_id, macros) {
  return {
    type: LOAD_MACRO_PROCESSES_SUCCESS,
    payload: { productivity_id, macros }
  };
}

export function getMacroProcessesFailure(error) {
  return {
    type: LOAD_MACRO_PROCESSES_FAILURE,
    error
  };
}

export function getProcesses(productivity_id, macro_id) {
  return {
    type: LOAD_PROCESSES,
    payload: { productivity_id, macro_id }
  };
}

export function getProcessesSuccess(processes) {
  return {
    type: LOAD_PROCESSES_SUCCESS,
    payload: processes
  };
}

export function getProcessesFailure(error) {
  return {
    type: LOAD_PROCESSES_FAILURE,
    error
  };
}

export function getActivities(id) {
  return {
    type: LOAD_ACTIVITIES,
    payload: id
  };
}

export function getActivitiesSuccess(activities) {
  return {
    type: LOAD_ACTIVITIES_SUCCESS,
    payload: activities
  };
}

export function getActivitiesFailure(error) {
  return {
    type: LOAD_ACTIVITIES_FAILURE,
    error
  };
}

export function createMacroProcess(productivity_id, description) {
  return {
    type: CREATE_MACRO_PROCESS,
    payload: { productivity_id, description }
  };
}

export function createMacroProcessSuccess(data) {
  return {
    type: CREATE_MACRO_PROCESS_SUCCESS,
    payload: data
  };
}

export function createMacroProcessFailure(error) {
  return {
    type: CREATE_MACRO_PROCESS_FAILURE,
    error
  };
}

export function createProcess(productivity_id, description, macro_id) {
  return {
    type: CREATE_PROCESS,
    payload: { productivity_id, description, macro_id }
  };
}

export function createProcessSuccess(data) {
  return {
    type: CREATE_PROCESS_SUCCESS,
    payload: data
  };
}

export function createProcessFailure(error) {
  return {
    type: CREATE_PROCESS_FAILURE,
    error
  };
}

export function createActivity(
  productivity_id,
  description,
  process_id,
  activity_action
) {
  return {
    type: CREATE_ACTIVITY,
    payload: { productivity_id, description, process_id, activity_action }
  };
}

export function createActivitySuccess(data) {
  return {
    type: CREATE_ACTIVITY_SUCCESS,
    payload: data
  };
}

export function createActivityFailure(error) {
  return {
    type: CREATE_ACTIVITY_FAILURE,
    error
  };
}

export function updateActivity(
  activity_id,
  productivity_id,
  description,
  activity_action
) {
  return {
    type: UPDATE_ACTIVITY,
    payload: {
      activity_id,
      productivity_id,
      description,
      activity_action
    }
  };
}

export function updateActivitySuccess(data) {
  return {
    type: UPDATE_ACTIVITY_SUCCESS,
    payload: data
  };
}

export function updateActivityFailure(error) {
  return {
    type: UPDATE_ACTIVITY_FAILURE,
    error
  };
}

export function fetchGenericPositions(productivity_id) {
  return {
    type: GET_GENERIC_POSITIONS,
    payload: productivity_id
  };
}

export function fetchGenericPositionsSuccess(data) {
  return {
    type: GET_GENERIC_POSITIONS_SUCCESS,
    payload: data
  };
}

export function fetchGenericPositionsFailure(error) {
  return {
    type: GET_GENERIC_POSITIONS_FAILURE,
    error
  };
}

export function createGenericPosition(productivity_id, name) {
  return {
    type: CREATE_GENERIC_POSITION,
    payload: { productivity_id, name }
  };
}

export function createGenericPositionSuccess(data) {
  return {
    type: CREATE_GENERIC_POSITION_SUCCESS,
    payload: data
  };
}
export function createGenericPositionFailure(error) {
  return {
    type: CREATE_GENERIC_POSITION_FAILURE,
    error
  };
}

export function fetchManagementPositions(productivity_id) {
  return {
    type: GET_MANAGEMENT_POSITIONS,
    payload: productivity_id
  };
}

export function fetchManagementPositionsSuccess(data) {
  return {
    type: GET_MANAGEMENT_POSITIONS_SUCCESS,
    payload: data
  };
}

export function fetchManagementPositionsFailure(error) {
  return {
    type: GET_MANAGEMENT_POSITIONS_FAILURE,
    error
  };
}

export function createManagementPosition(productivity_id, name) {
  return {
    type: CREATE_MANAGEMENT_POSITION,
    payload: { productivity_id, name }
  };
}

export function createManagementPositionSuccess(data) {
  return {
    type: CREATE_MANAGEMENT_POSITION_SUCCESS,
    payload: data
  };
}
export function createManagementPositionFailure(error) {
  return {
    type: CREATE_MANAGEMENT_POSITION_FAILURE,
    error
  };
}

export function fetchWorkPositions(productivity_id) {
  return {
    type: GET_WORK_POSITIONS,
    payload: productivity_id
  };
}

export function fetchWorkPositionsSuccess(data) {
  return {
    type: GET_WORK_POSITIONS_SUCCESS,
    payload: data
  };
}

export function fetchWorkPositionsFailure(error) {
  return {
    type: GET_WORK_POSITIONS_FAILURE,
    error
  };
}

export function createWorkPosition(productivity_id, name) {
  return {
    type: CREATE_WORK_POSITION,
    payload: { productivity_id, name }
  };
}

export function createWorkPositionSuccess(data) {
  return {
    type: CREATE_WORK_POSITION_SUCCESS,
    payload: data
  };
}
export function createWorkPositionFailure(error) {
  return {
    type: CREATE_WORK_POSITION_FAILURE,
    error
  };
}

export function editProductivity(productivity_id, data) {
  return {
    type: EDIT_PRODUCTIVITY,
    payload: { productivity_id, data }
  };
}

export function editProductivitySuccess(data) {
  return {
    type: EDIT_PRODUCTIVITY_SUCCESS,
    payload: data
  };
}
export function editProductivityFailure(error) {
  return {
    type: EDIT_PRODUCTIVITY_FAILURE,
    error
  };
}

import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOAD_COMPANIES,
  LOAD_COMPANY,
  CREATE_PRODUCTIVITY,
  CREATE_COMPANY
} from "containers/Companies/constants";
import {
  companiesLoaded,
  companiesLoadingError,
  companyLoaded,
  createProductivitySuccess,
  createProductivityFailure,
  createCompanySuccess,
  createCompanyFailure
} from "containers/Companies/actions";
import Axios from "utils/axios";
import { push } from "connected-react-router";

const getCompaniesInfoAPI = () => {
  const baseUrl = "/companies";

  return Axios.get(baseUrl)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

const getCompanyInfoAPI = id => {
  return Axios.get(`/companies/${id}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

function createProductivityRequest(company_id, name) {
  return Axios.post("/productivity/", { name, company_id })
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function createCompanyRequest(name) {
  return Axios.post("/companies", { name })
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

export function* getCompanies() {
  try {
    const data = yield call(getCompaniesInfoAPI);
    yield put(companiesLoaded(data));
  } catch (err) {
    yield put(companiesLoadingError(err));
  }
}

export function* getCompany(action) {
  try {
    const data = yield call(getCompanyInfoAPI, action.payload);
    yield put(companyLoaded(data));
  } catch (err) {
    yield put(push("/404"));
  }
}

export function* createProductivity(action) {
  try {
    const data = yield call(
      createProductivityRequest,
      action.payload.companyId,
      action.payload.name
    );
    yield put(createProductivitySuccess(data));
  } catch (err) {
    yield put(createProductivityFailure(err));
  }
}

export function* createCompany(action) {
  try {
    const data = yield call(createCompanyRequest, action.payload);
    yield put(createCompanySuccess(data));
  } catch (err) {
    yield put(createCompanyFailure(err));
  }
}

export default function* companiesData() {
  yield takeLatest(LOAD_COMPANIES, getCompanies);
  yield takeLatest(LOAD_COMPANY, getCompany);
  yield takeLatest(CREATE_PRODUCTIVITY, createProductivity);
  yield takeLatest(CREATE_COMPANY, createCompany);
}

import React from "react";
import { Field, Control, Input, Button, Label } from "rbx";
import Switch from "react-switch";

function JobPositionsTree() {
  return (
    <div>
      Postions
      <Field kind="group">
        <Control>
          <Input placeholder="Nombre" size="medium" />
        </Control>
        <Control>
          <Input placeholder="Tipo" size="medium" />
        </Control>
        <Field horizontal className="m-t-5">
          <Switch
            onChange={() => {}}
            checked={true}
            onColor="#ffa700"
            offColor="#434452"
          />
          <Field.Label size="normal" className="m-l-5">
            <Label>Is Group?</Label>
          </Field.Label>
        </Field>
        <Control>
          <Button color="primary" size="medium" className="p-l-30 p-r-30">
            Crear
          </Button>
        </Control>
      </Field>
    </div>
  );
}

export default JobPositionsTree;

import { createSelector } from "reselect";
import { initialState } from "./reducer";
import get from "lodash/get";
import filter from "lodash/filter";

const selectProducts = state => state.products || initialState;
const selectCurrentId = createSelector(
  selectProducts,
  products => {
    return products.current;
  }
);

const makeSelectProductsData = () =>
  createSelector(
    selectProducts,
    productsState => productsState.data
  );

const makeSelectProcesses = () =>
  createSelector(
    selectProducts,
    productsState => Object.values(productsState.processes)
  );

const makeSelectActivities = () =>
  createSelector(
    selectProducts,
    productsState => Object.values(productsState.activities)
  );

const makeSelectGenericPositions = () =>
  createSelector(
    selectProducts,
    productsState => Object.values(productsState.generic_positions)
  );

const makeSelectManagements = () =>
  createSelector(
    selectProducts,
    productsState => Object.values(productsState.managements)
  );

const makeSelectWorkPositions = () =>
  createSelector(
    selectProducts,
    productsState => Object.values(productsState.work_positions)
  );

const makeSelectMacrosData = () =>
  createSelector(
    selectProducts,
    productsState => productsState.macros
  );

const makeSelectCurrentProductivity = () =>
  createSelector(
    makeSelectProductsData(),
    selectCurrentId,
    (products, currentId) => {
      return get(products, currentId, {});
    }
  );

const makeSelectCurrentMacros = () =>
  createSelector(
    makeSelectMacrosData(),
    selectCurrentId,
    (macros, currentProductId) => {
      return filter(macros, m => m.productivity_id === currentProductId);
    }
  );

const makeSelectCurrentGenericPositions = () =>
  createSelector(
    makeSelectGenericPositions(),
    selectCurrentId,
    (positions, currentProductId) => {
      return filter(positions, m => m.productivity_id === currentProductId);
    }
  );

const makeSelectCurrentWorkPositions = () =>
  createSelector(
    makeSelectWorkPositions(),
    selectCurrentId,
    (positions, currentProductId) => {
      return filter(positions, m => m.productivity_id === currentProductId);
    }
  );

const makeSelectCurrentManagementsPositions = () =>
  createSelector(
    makeSelectManagements(),
    selectCurrentId,
    (positions, currentProductId) => {
      return filter(positions, m => m.productivity_id === currentProductId);
    }
  );

export {
  selectProducts,
  makeSelectProductsData,
  makeSelectCurrentProductivity,
  makeSelectCurrentMacros,
  makeSelectProcesses,
  makeSelectActivities,
  makeSelectCurrentGenericPositions,
  makeSelectCurrentWorkPositions,
  makeSelectCurrentManagementsPositions
};

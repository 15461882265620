import React from "react";
import { Route } from "react-router-dom";
import CompaniesView from "containers/Companies";
import CompanyDetailsView from "containers/Companies/CompanyDetails";
import ProductsView from "containers/Products/";
import ProductDetailsView from "containers/Products/ProductDetails";
import "sanitize.css/sanitize.css";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import history from "utils/history";
import configureStore from "configureStore";
import Login from "containers/Login";
import requireAuth from "./requireAuth";
import DashboardLayoutRoute from "containers/App/DashboardLayout";
import { positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./AlertTemplate";

const alertOptions = {
  timeout: 5000,
  position: positions.TOP_RIGHT
};

// Create redux store with history
const initialState = {};
export const store = configureStore(initialState, history);

function App() {
  return (
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <ConnectedRouter history={history}>
          <DashboardLayoutRoute
            path="/"
            exact
            component={requireAuth(CompaniesView)}
          />
          <DashboardLayoutRoute
            path="/companies"
            exact
            component={requireAuth(CompaniesView)}
          />
          <DashboardLayoutRoute
            path="/companies/:id"
            exact
            component={requireAuth(CompanyDetailsView)}
          />
          <DashboardLayoutRoute
            path="/products"
            exact
            component={requireAuth(ProductsView)}
          />
          <DashboardLayoutRoute
            path="/products/:id"
            exact
            component={requireAuth(ProductDetailsView)}
          />
          <Route path="/login" exact component={Login} />
          <Route path="/404" exact component={() => "Pagina no encontrada"} />
        </ConnectedRouter>
      </AlertProvider>
    </Provider>
  );
}

export default App;

import React, { PureComponent } from "react";
import { Container, Content } from "./styledComponents";
import { PageTitle } from "components/PageTitle/PageTitle";

export class ProductsView extends PureComponent {
  handleClickItem = id => {
    this.props.history.push(`/companies/${id}`);
  };
  render() {
    return (
      <Container>
        <Content>
          <div>
            <PageTitle>PRODUCTS</PageTitle>
          </div>
        </Content>
      </Container>
    );
  }
}

export default ProductsView;

import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  left: 0;
  height: 100%;
  width: 18.33em;
  background-color: #202938;
  border: 1px solid #707070;
  padding-top: 1.67em;
`;

export const Heading = styled.div`
  margin-top: 0;
  margin-bottom: 4.2em;
  margin-left: 2.17em;
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  display: inline-block;
  height: 3em;
  width: 3em;
`;

export const Title = styled.span`
  font-size: 1.5em;
  color: #ffffff;
  font-weight: bold;
  margin-left: 1em;
  display: inline-flex;
  font-family: "San Francisco";
  font-weight: 700;
`;

export const NavList = styled.ul`
  list-style: none;
  background-color: "red";
  width: 100%;
  margin: 0 !important;
  margin-top: 3.33em !important;
  padding: 0;
  & > a > li:hover,
  & > a.active > li {
    background: rgba(255, 255, 255, 0.12);
  }
`;

export const NavListItem = styled.li`
  list-style: none;
  height: 2.71em;
  padding-left: 1.7em;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 1.17em;
  font-family: "San Francisco";
  font-weight: 500;
`;

export const NavListItemText = styled.p`
  display: inline-flex;
  margin-left: 0.65em;
`;

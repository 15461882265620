import React from "react";
import { Notification, Delete } from "rbx";

// the style contains only the margin given as offset
// options contains all alert given options
// message is the alert message
// close is a function that closes the alert

const getColor = type => {
  switch (type) {
    case "success":
      return "success";
    case "info":
      return "info";
    case "error":
      return "danger";
    default:
      return "primary";
  }
};

const AlertTemplate = ({ style, options, message, close }) => (
  <div style={style}>
    <Notification color={getColor(options.type)}>
      <Delete as="button" onClick={close} />
      <div className="p-l-20 p-r-20 p-t-5 p-b-5">{message}</div>
    </Notification>
  </div>
);

export default AlertTemplate;

import React, { useState, useEffect } from "react";
import { SkyLightStateless } from "react-skylight";
import {
  ModalFormGroup,
  ModalLabel,
  ModalInput,
  ModalSubmit
} from "./styledComponents";

const modalStyles = {
  dialog: {
    border: "1px solid #707070",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  closeButton: {
    color: "#FFA700",
    right: 20,
    top: 10,
    fontSize: "3.5em"
  },
  title: {
    fontSize: "1.67em",
    marginTop: "2.5em",
    marginBottom: "3em",
    color: "#434452",
    fontWeight: "bold"
  }
};

function NewItemModal({
  isVisible,
  toggleModal,
  onSubmit,
  label = "",
  submitText = "",
  title = "",
  id = ""
}) {
  const [value, setValue] = useState("");
  const updateValue = ({ target }) => setValue(target.value);
  useEffect(() => {
    if (!isVisible && value) {
      setValue("");
    }
  }, [setValue, isVisible, value]);
  const handleSubmit = () => {
    if (value && value.trim()) {
      onSubmit && onSubmit(value);
    }
  };
  return (
    <SkyLightStateless
      isVisible={isVisible}
      dialogStyles={modalStyles.dialog}
      closeButtonStyle={modalStyles.closeButton}
      titleStyle={modalStyles.title}
      title={title}
      onOverlayClicked={toggleModal}
      onCloseClicked={toggleModal}
    >
      <ModalFormGroup>
        <ModalLabel>{label}</ModalLabel>
        <ModalInput
          value={value}
          onChange={updateValue}
          autoFocus={true}
          data-cy={`${id}-input-modal`}
        />
        <ModalSubmit data-cy={`${id}-submit-modal`} onClick={handleSubmit}>
          {submitText}
        </ModalSubmit>
      </ModalFormGroup>
    </SkyLightStateless>
  );
}

export default NewItemModal;

import React, { useState, useEffect, memo } from "react";
import SimpleTopBar from "components/SimpleTopBar/SimpleTopBar";
import { Content } from "rbx";
import {
  Content as CompanyContent,
  ActionsContainer,
  CompanyProductsContainer,
  ModalFormGroup,
  ModalLabel,
  ModalInput,
  ModalSubmit
} from "./styledComponents";
import { PageTitle } from "components/PageTitle/PageTitle";
import { SkyLightStateless } from "react-skylight";
import { createStructuredSelector } from "reselect";
import { useInjectReducer } from "utils/injectReducer";
import { useInjectSaga } from "utils/injectSaga";
import reducer from "./reducer";
import saga from "./saga";
import { connect } from "react-redux";
import { compose } from "redux";
import { makeSelectCurrentCompany } from "./selectors";
import {
  loadCompany,
  setCurrentCompanyId,
  createProductivity
} from "containers/Companies/actions";
import get from "lodash/get";
import CompanyProduct from "./CompanyProduct";

const modalStyles = {
  dialog: {
    border: "1px solid #707070",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  closeButton: {
    color: "#FFA700",
    right: 20,
    top: 10,
    fontSize: "3.5em"
  },
  title: {
    fontSize: "1.67em",
    marginTop: "2.5em",
    marginBottom: "3em",
    color: "#434452",
    fontWeight: "bold"
  }
};

const key = "companies";

function CompanyDetailsView({
  match: {
    params: { id }
  },
  getCompanyById,
  setCurrentCompany,
  company,
  newProductivity
}) {
  useInjectReducer({ key, reducer });
  // @ts-ignore
  useInjectSaga({ key, saga });
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [productName, setProductName] = useState("");
  const toggleModal = () => {
    if (modalIsVisible) {
      setProductName("");
    }
    setModalIsVisible(!modalIsVisible);
  };
  useEffect(() => {
    setCurrentCompany(id);
  }, [setCurrentCompany, id]);
  useEffect(() => {
    getCompanyById(id);
  }, [getCompanyById, id]);
  const updateName = ({ target }) => {
    setProductName(target.value);
  };
  const submitNewProductivity = () => {
    if (productName.trim()) {
      newProductivity(id, productName.trim());
      setModalIsVisible(false);
    }
  };
  return (
    <Content>
      <SimpleTopBar />
      <SkyLightStateless
        isVisible={modalIsVisible}
        dialogStyles={modalStyles.dialog}
        closeButtonStyle={modalStyles.closeButton}
        titleStyle={modalStyles.title}
        title="Crear version de producto"
        onOverlayClicked={toggleModal}
        onCloseClicked={toggleModal}
      >
        <ModalFormGroup>
          <ModalLabel>Name</ModalLabel>
          <ModalInput value={productName} onChange={updateName} />
          <ModalSubmit onClick={submitNewProductivity}>
            CREAR VERSION
          </ModalSubmit>
        </ModalFormGroup>
      </SkyLightStateless>
      <CompanyContent>
        <ActionsContainer>
          <PageTitle>{get(company, "name", "").toUpperCase()}</PageTitle>
        </ActionsContainer>
        <CompanyProductsContainer>
          <CompanyProduct
            toggleModal={toggleModal}
            title="PRODUCTIVIDAD"
            versions={get(company, "productivities", [])}
          />
        </CompanyProductsContainer>
      </CompanyContent>
    </Content>
  );
}

const mapStateToProps = createStructuredSelector({
  company: makeSelectCurrentCompany()
});

const mapDispatchToProps = {
  getCompanyById: id => loadCompany(id),
  setCurrentCompany: id => setCurrentCompanyId(id),
  newProductivity: (companyId, name) => createProductivity(companyId, name)
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withConnect,
  memo
)(CompanyDetailsView);

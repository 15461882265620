import React, { memo, useState, useEffect, useCallback } from "react";
import { Title } from "./styledComponents";
import { connect } from "react-redux";
import { compose } from "redux";
import { useInjectReducer } from "utils/injectReducer";
import { useInjectSaga } from "utils/injectSaga";
import { loginUser } from "./actions";
import get from "lodash/get";
import reducer from "./reducer";
import saga from "./saga";
import {
  Card,
  Container,
  Column,
  Field,
  Control,
  Button,
  Input,
  Hero,
  Help
} from "rbx";
import { useAlert } from "react-alert";

const key = "login";

function Login({ asyncError, onSubmit, loading }) {
  const [values, setValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});

  const alert = useAlert();
  const notify = useCallback(alert.show, []);

  useEffect(() => {
    if (asyncError) {
      notify(asyncError.message, { type: "error" });
    }
  }, [asyncError, notify]);

  const updateValue = key => ({ target }) =>
    setValues({ ...values, [key]: target.value });

  const loginWasClickedCallback = e => {
    e.preventDefault();
    const formErrors = validateFormValues(values);
    if (Object.keys(formErrors).length) {
      return setErrors(formErrors);
    } else {
      onSubmit(values);
      return setErrors(formErrors);
    }
  };

  useInjectReducer({ key, reducer });
  // @ts-ignore
  useInjectSaga({ key, saga });

  const validateFormValues = ({ email, password }) => {
    let e = {};
    if (!email) {
      e.email = "This field is required";
    }

    if (!password) {
      e.password = "This field is required";
    }

    return e;
  };

  return (
    <Hero size="fullheight" color="primary">
      <Hero.Body>
        <Container>
          <Column.Group centered>
            <Column
              mobile={{ size: "three-quarters" }}
              tablet={{ size: "two-thirds" }}
              desktop={{ size: "half" }}
              widescreen={{ size: "one-third" }}
              fullhd={{ size: "one-quarter" }}
            >
              <Card>
                <Card.Content>
                  <Title>Actum</Title>
                  <form>
                    <Field>
                      <Control>
                        <Input
                          type="email"
                          placeholder="Email"
                          name="email"
                          size="medium"
                          required
                          onChange={updateValue("email")}
                          value={values.email}
                          color={errors.email ? "danger" : undefined}
                          data-cy="email"
                        />
                      </Control>
                      {errors.email && (
                        <Help color="danger">This field is required</Help>
                      )}
                    </Field>
                    <Field>
                      <Control>
                        <Input
                          type="password"
                          placeholder="Password"
                          name="password"
                          size="medium"
                          required
                          onChange={updateValue("password")}
                          value={values.password}
                          color={errors.password ? "danger" : undefined}
                          data-cy="password"
                        />
                      </Control>
                      {errors.password && (
                        <Help color="danger">This field is required</Help>
                      )}
                    </Field>
                    <Field>
                      <Control>
                        <Button
                          color="primary"
                          size="medium"
                          fullwidth
                          onClick={loginWasClickedCallback}
                          state={loading ? "loading" : undefined}
                          data-cy="submit-login"
                        >
                          Login
                        </Button>
                      </Control>
                    </Field>
                  </form>
                </Card.Content>
              </Card>
            </Column>
          </Column.Group>
        </Container>
      </Hero.Body>
    </Hero>
  );
}

const mapStateToProps = state => {
  return {
    loading: get(state, "login.loading", false),
    asyncError: get(state, "login.asyncError", "")
  };
};

const mapDispatchToProps = {
  onSubmit: values => loginUser(values)
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default compose(
  withConnect,
  memo
)(Login);

import { call, put, takeLatest } from "redux-saga/effects";
import { LOGIN_USER } from "./constants";
import Axios from "utils/axios";
import { push } from "connected-react-router";
import { loginUserSuccess, loginUserFailure } from "containers/Login/actions";

function loginRequest(values) {
  return Axios.post("/login", values)
    .then(res => res.data)
    .catch(() => {
      throw new Error(`Auth error, please check your credentials`);
    });
}

function* login(action) {
  try {
    const { token } = yield call(loginRequest, action.payload);
    localStorage.setItem("jwt", token);
    yield put(loginUserSuccess());
    yield put(push("/"));
  } catch (error) {
    yield put(loginUserFailure(error));
  }
}

export default function* companiesData() {
  yield takeLatest(LOGIN_USER, login);
}

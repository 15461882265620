import React from "react";
import {
  CompanyProduct,
  ProductHeader,
  ProductTitle,
  ProductBody,
  ProductBodyItem,
  SeeDetails,
  ProductVersion
} from "./styledComponents";
import Button from "components/Button/Button";
import { Link } from "react-router-dom";
import ArrowRight from "components/ArrowRight/ArrowRight";

export default function CP({ title, toggleModal, versions = [] }) {
  return (
    <CompanyProduct>
      <ProductHeader>
        <ProductTitle>{title}</ProductTitle>
        <Button onClick={toggleModal}>+ Crear nueva </Button>
      </ProductHeader>
      <ProductBody>
        {versions.map(ver => (
          <ProductBodyItem key={ver.id}>
            <ProductVersion>
              {ver.name ? `${ver.name} - ${ver.code}` : ""}
            </ProductVersion>
            <Link to={{ pathname: `/products/${ver.id}`, from: "company" }}>
              <SeeDetails>See Details</SeeDetails>
              <ArrowRight />
            </Link>
          </ProductBodyItem>
        ))}
      </ProductBody>
    </CompanyProduct>
  );
}

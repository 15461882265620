import React from "react";

export default ({ width = 23, height = 23, color = "#ffa700" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g transform="translate(-265 -174)">
        <path
          d="M14.336,5.94,13.4,5,9.668,8.728,5.94,5,5,5.94,8.728,9.668,5,13.4l.94.94,3.728-3.728L13.4,14.336l.94-.94L10.608,9.668Z"
          transform="translate(262.929 185.602) rotate(-45)"
          fill={color}
        />
        <g
          transform="translate(265 174)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        >
          <circle cx="11.5" cy="11.5" r="11.5" stroke="none" />
          <circle cx="11.5" cy="11.5" r="11" fill="none" />
        </g>
      </g>
    </svg>
  );
};

import React from "react";
import { Column } from "rbx";
import LineChart from "components/Reports/LineChart";

function ReportsTab() {
  return (
    <Column.Group>
      <Column>
        <LineChart />
      </Column>
      <Column>
        <LineChart />
      </Column>
      <Column>
        <LineChart />
      </Column>
      <Column>
        <LineChart />
      </Column>
    </Column.Group>
  );
}

export default ReportsTab;

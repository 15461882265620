import axios from "axios";
import { push } from "connected-react-router";
import { store } from "containers/App";

const instance = axios.create({
  baseURL: "https://actumm.com/api",
  headers: { "Content-Type": "application/json" }
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem("jwt");
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`
      }
    };
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.config.url !== "https://actumm.com/api/login"
    ) {
      localStorage.removeItem("jwt");
      store.dispatch(push("/login"));
    }
    return Promise.reject(error);
  }
);

export default instance;

import React from "react";
import GlobalNavigation from "components/GlobalNavigation/GlobalNavigation";
import { Route } from "react-router-dom";
import { Content } from "rbx";
import styled from "styled-components";

const Container = styled(Content)`
  margin-left: 18.33em;
`;

function DashboardLayout({ children }) {
  return (
    <Container>
      <GlobalNavigation />
      {children}
    </Container>
  );
}

function DashboardLayoutRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <DashboardLayout>
          <Component {...matchProps} />
        </DashboardLayout>
      )}
    />
  );
}

export default DashboardLayoutRoute;

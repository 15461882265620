import React from "react";
import { connect } from "react-redux";

export default function(WrappedComponent) {
  class Authentication extends React.PureComponent {
    state = {
      authenticated: false
    };
    componentWillMount() {
      this.checkAuthentication();
    }
    checkAuthentication = () => {
      if (!localStorage.getItem("jwt")) {
        return this.props.history.push("/login");
      }
      this.setState(() => ({ authenticated: true }));
    };
    render() {
      const { authenticated } = this.state;
      return authenticated ? <WrappedComponent {...this.props} /> : null;
    }
  }
  const mapStateToProps = ({ global }) => ({ auth: global.token });

  return connect(mapStateToProps)(Authentication);
}

import produce from "immer";
import {
  LOGIN_USER,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS
} from "./constants";

// The initial state of the App
export const initialState = {
  loading: false,
  asyncError: ""
};

const appReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case LOGIN_USER:
        draft.loading = true;
        return draft;
      case LOGIN_USER_SUCCESS:
        draft.loading = false;
        return draft;
      case LOGIN_USER_FAILURE:
        draft.loading = false;
        draft.asyncError = payload.error;
        return draft;
      default:
        break;
    }
  });

export default appReducer;

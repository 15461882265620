import styled from "styled-components";

export const Container = styled.div`
  height: 5em;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1.75em;
  flex-shrink: 0;
  box-shadow: 0px 0.25em 0.5em 0px rgba(0, 0, 0, 0.06);
`;

export const Name = styled.p`
  margin: 0;
  margin-right: 1.25em;
  font-size: 1.17em;
  display: inline-block;
  color: #434452;
  font-weight: 500;
`;

export const Avatar = styled.img`
  margin: 0;
  height: 2.93em;
  width: 2.93em;
  display: inline-block;
`;

import styled from "styled-components";

export default styled.button`
  height: 3.33em;
  background-color: ${props => (props.transparent ? "transparent" : "#ffa700")};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.transparent ? "#ffa700" : "#fff")};
  border: ${props => (props.transparent ? "1px solid #ffa700" : "none")};
  font-weight: bold;
  outline: none;
  border-radius: 3px;
  min-width: 10.83em;
  cursor: pointer;
  &:hover {
    background-color: ${props =>
      props.transparent ? "rgba(112,112,112, .01)" : "#ffa700"};
  }
  &:active {
    position: relative;
    top: 1px;
  }
`;

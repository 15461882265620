import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* height: 100vh;
  max-height: 100vh;
  overflow-y: scroll; */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2.4em 2.83em;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;
`;

export const CompanyProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CompanyProduct = styled.div`
  display: inline-flex;
  flex-direction: column;
  min-height: 19.75em;
  width: 36.42em;
  background: #fff;
  margin-right: 1.67em;
  margin-bottom: 1.67em;
  box-shadow: 0px 0.25em 0.5em 0px rgba(0, 0, 0, 0.2);
`;

export const ProductHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 5em;
  background: #eff5ff;
  padding-left: 2.33em;
  padding-right: 2.5em;
`;

export const ProductTitle = styled.span`
  font-size: 1.17em;
  font-weight: 600;
  margin: 0;
`;

export const ProductVersion = styled.span`
  font-size: 1.08em;
  margin: 0;
  font-weight: normal;
  color: #5f6176;
  flex: 1;
`;

export const SeeDetails = styled.b`
  font-size: 1.17em;
  margin-right: 0.83em;
  color: #ffa700;
  font-weight: 600;
  flex-shrink: 0;
`;

export const LinkContainer = styled.div`
  display: inline-flex;
`;

export const ProductBody = styled.div`
  padding: 0.33em 2.875em 2.5em 2.375em;
`;

export const ProductBodyItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 41, 56, 0.14);
  padding-bottom: 1.21em;
  padding-top: 1.34em;
`;

export const ModalFormGroup = styled.div`
  width: 40em;
  max-width: 70%;
  display: flex;
  flex-direction: column;
`;

export const ModalLabel = styled.label`
  font-size: 1.08em;
  color: rgba(95, 97, 118, 0.51);
`;

export const ModalInput = styled.input`
  display: flex;
  border: 1px solid rgba(32, 41, 56, 0.5);
  color: #434452;
  font-size: 1.17em;
  font-weight: 600;
  border-radius: 0.22em;
  height: 2em;
  margin-top: 0.4em;
  padding-left: 1.22em;
  transition-duration: 0.3s;
  &:focus,
  &:active {
    outline: 0;
    border: 1px solid #ffa700;
  }
`;

export const ModalSubmit = styled.button`
  height: 3.21em;
  widows: 100%;
  margin-top: 2.85em;
  background-color: #ffa700;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.17em;
  border: none;
  border-radius: 0.357em;
  cursor: pointer;
  &:focus,
  &:active {
    outline: 0;
  }
  &:active {
    position: relative;
    top: 1px;
  }
`;

import React, { useState, useEffect } from "react";
import ProductsToolbar from "components/ProductsToolbar/ProductsToolbar";
import { Container, Content } from "./styledComponents";
import querystringify from "querystringify";
import { connect } from "react-redux";
import reducer from "./reducer";
import saga from "./saga";
import { createStructuredSelector } from "reselect";
import { useInjectReducer } from "utils/injectReducer";
import { useInjectSaga } from "utils/injectSaga";
import {
  makeSelectCurrentProductivity,
  makeSelectCurrentMacros,
  makeSelectProcesses,
  makeSelectActivities,
  makeSelectCurrentGenericPositions,
  makeSelectCurrentManagementsPositions,
  makeSelectCurrentWorkPositions
} from "./selectors";
import {
  getProduct,
  setCurrentProductId,
  getMacroProcesses,
  getProcesses,
  getActivities,
  createMacroProcess,
  createProcess,
  createActivity,
  updateActivity,
  fetchWorkPositions,
  createWorkPosition
} from "./actions";
import ProcessTab from "./ProcessTab";
import DefaultValuesTab from "./DefaultValuesTab";
import ReportsTab from "./ReportsTab";
import get from "lodash/get";
import { editProductivity } from "containers/Products/actions";

const key = "products";

const ProductDetailsView = ({
  location: { search, from },
  match: {
    params: { id }
  },
  getProductivityById,
  history: { push, pathname, replace },
  productivity,
  setCurrentProductivity,
  getMacros,
  getProcesses,
  getActivities,
  macros,
  newMacro,
  processes,
  newProcess,
  activities,
  newActivity,
  editActivity,
  getWorkPositions,
  newWorkPosition,
  updateProductivity
}) => {
  useInjectReducer({ key, reducer });
  // @ts-ignore
  useInjectSaga({ key, saga });

  useEffect(() => {
    setCurrentProductivity(id);
  }, [setCurrentProductivity, id]);
  const [comingFrom] = useState(from);
  const qs = querystringify.parse(search);
  const [selectedTab, setTab] = useState(get(qs, "tab", "process"));
  const [selectedMacro, setMacro] = useState(Number(get(qs, "macro", null)));
  const [selectedProcess, setProccess] = useState(
    Number(get(qs, "process", null))
  );

  const handleGoBack = () => {
    if (comingFrom === "company") {
      push(`/companies/${productivity.company_id}`);
    } else {
      push("/products");
    }
  };

  useEffect(() => {
    getProductivityById(id);
  }, [getProductivityById, id]);

  useEffect(() => {
    getMacros(id);
  }, [getMacros, id]);

  useEffect(() => {
    if (selectedMacro) {
      getProcesses(id, selectedMacro);
    }
  }, [getProcesses, id, selectedMacro]);

  useEffect(() => {
    if (selectedProcess) {
      getActivities(id, selectedProcess);
    }
  }, [getActivities, id, selectedProcess]);

  useEffect(() => {
    if (selectedTab === "defaultValues") {
      getWorkPositions(id);
    }
  }, [getWorkPositions, id, selectedTab]);

  const handleEdit = data => {
    updateProductivity(id, data);
  };

  const updateMacro = value => {
    setMacro(value);
    setProccess(null);
    qs.macro = value;
    replace({
      pathname,
      search: querystringify.stringify(qs)
    });
  };
  const updateProcess = value => {
    setProccess(value);
    qs.process = value;
    replace({
      pathname,
      search: querystringify.stringify(qs)
    });
  };
  const updateTab = value => {
    setTab(value);
    qs.tab = value;
    replace({
      pathname,
      search: querystringify.stringify(qs)
    });
    return;
  };

  return (
    <Container>
      <ProductsToolbar
        title={
          productivity.name ? `${productivity.name} - ${productivity.code}` : ""
        }
        selectedTab={selectedTab}
        onChange={updateTab}
        onGoBack={handleGoBack}
      />
      <Content>
        {(() => {
          if (selectedTab === "defaultValues") {
            return (
              <DefaultValuesTab
                working_hours={productivity.working_hours}
                unproductive_percent={productivity.unproductive_percent}
                newWorkPosition={name => newWorkPosition(id, name)}
              />
            );
          } else if (selectedTab === "process") {
            return (
              <ProcessTab
                newMacro={description => newMacro(id, description)}
                macros={macros}
                selectMacro={updateMacro}
                selectedMacro={selectedMacro}
                selectProcess={updateProcess}
                selectedProcess={selectedProcess}
                processes={processes}
                newProcess={description =>
                  newProcess(id, description, selectedMacro)
                }
                activities={activities}
                newActivity={(selectedProcess, description, action) =>
                  newActivity(id, description, selectedProcess, action)
                }
                editActivity={(activity_id, description, action) =>
                  editActivity(activity_id, id, description, action)
                }
                onEdit={handleEdit}
                isEnable={productivity.enabled}
              />
            );
          } else if (selectedTab === "reports") {
            return <ReportsTab />;
          }
          return null;
        })()}
      </Content>
    </Container>
  );
};

const mapStateToProps = createStructuredSelector({
  productivity: makeSelectCurrentProductivity(),
  macros: makeSelectCurrentMacros(),
  processes: makeSelectProcesses(),
  activities: makeSelectActivities(),
  genericPositions: makeSelectCurrentGenericPositions(),
  managementPositions: makeSelectCurrentManagementsPositions(),
  workPositions: makeSelectCurrentWorkPositions()
});

const mapDispatchToProps = {
  getProductivityById: id => getProduct(id),
  getMacros: productivity_id => getMacroProcesses(productivity_id),
  getProcesses: (productivity_id, macro_id) =>
    getProcesses(productivity_id, macro_id),
  getActivities: productivity_id => getActivities(productivity_id),
  setCurrentProductivity: id => setCurrentProductId(id),
  newMacro: (productivity_id, description) =>
    createMacroProcess(productivity_id, description),
  newProcess: (productivity_id, description, macro_id) =>
    createProcess(productivity_id, description, macro_id),
  newActivity: (productivity_id, description, process_id, action) =>
    createActivity(productivity_id, description, process_id, action),
  editActivity: (activity_id, productivity_id, description, action) =>
    updateActivity(activity_id, productivity_id, description, action),
  getWorkPositions: productivity_id =>
    fetchWorkPositions(Number(productivity_id)),
  newWorkPosition: (productivity_id, name) =>
    createWorkPosition(Number(productivity_id), name),
  updateProductivity: (companyId, data) => editProductivity(companyId, data)
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(ProductDetailsView);

import produce from "immer";
import {
  LOAD_COMPANIES_SUCCESS,
  LOAD_COMPANY_SUCCESS,
  SET_CURRENT_COMPANY,
  CREATE_PRODUCTIVITY_SUCCESS,
  CREATE_COMPANY_SUCCESS
} from "./constants";

export const initialState = {
  data: {},
  current: undefined
};

const appReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case SET_CURRENT_COMPANY:
        draft.current = Number(payload.id);
        break;
      case LOAD_COMPANIES_SUCCESS:
        draft.data = payload.data.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
        break;
      case LOAD_COMPANY_SUCCESS:
        const { company } = payload;
        draft.data[company.id] = company;
        break;
      case CREATE_PRODUCTIVITY_SUCCESS:
        const { company_id } = payload;
        draft.data[company_id].productivities = [
          ...draft.data[company_id].productivities,
          payload
        ];
        break;
      case CREATE_COMPANY_SUCCESS:
        draft.data[payload.id] = payload;
        break;
      default:
        break;
    }
  });

export default appReducer;

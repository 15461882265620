export const LOAD_COMPANIES = "dashboard/Companies/LOAD_COMPANIES";
export const LOAD_COMPANIES_SUCCESS =
  "dashboard/Companies/LOAD_COMPANIES_SUCCESS";
export const LOAD_COMPANIES_ERROR = "dashboard/Companies/LOAD_COMPANIES_ERROR";
export const LOAD_COMPANY = "dashboard/Companies/LOAD_COMPANY";
export const LOAD_COMPANY_SUCCESS = "dashboard/Companies/LOAD_COMPANY_SUCCESS";
export const LOAD_COMPANY_ERROR = "dashboard/Companies/LOAD_COMPANY_ERROR";
export const SET_CURRENT_COMPANY = "dashboard/Companies/SET_CURRENT_COMPANY";
export const CREATE_PRODUCTIVITY = "dashboard/Companies/CREATE_PRODUCTIVITY";
export const CREATE_PRODUCTIVITY_SUCCESS =
  "dashboard/Companies/CREATE_PRODUCTIVITY_SUCCESS";
export const CREATE_PRODUCTIVITY_FAILURE =
  "dashboard/Companies/CREATE_PRODUCTIVITY_FAILURE";
export const CREATE_COMPANY = "dashboard/Companies/CREATE_COMPANY";
export const CREATE_COMPANY_SUCCESS =
  "dashboard/Companies/CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILURE =
  "dashboard/Companies/CREATE_COMPANY_FAILURE";

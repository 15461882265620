import styled from "styled-components";

export const TableContainer = styled.div`
  box-shadow: 0px 0.25em 0.5em 0px rgba(0, 0, 0, 0.2);
  & > table {
    width: 100%;
    border-collapse: collapse;
    background: white;
  }
`;

export const THead = styled.thead`
  background: #eff5ff;
  height: 3.4em;
  & > tr {
    height: 3.4em;
  }
  & > tr > th {
    text-align: left;
    font-family: 600;
    font-size: 1.17em;
    color: #434452;
    vertical-align: middle;
    &:first-child {
      padding-left: 4.5em;
      @media (min-width: 1024px) {
        padding-left: 1.5em;
      }
    }
    &:last-child {
      padding-right: 4.5em;
      @media (min-width: 1024px) {
        padding-right: 1.5em;
      }
    }
  }
`;

export const TBody = styled.tbody`
  & > tr:hover {
    background: rgba(112, 112, 112, 0.1);
    cursor: pointer;
  }
`;

export const TD = styled.td`
  padding: 1em 0;
  font-size: 1.09em;
  color: #5f6176;
  height: 6.17em;
  line-height: 1.4;
  border-bottom: 1px solid rgba(112, 112, 112, 0.27);
  &:first-child {
    padding-left: 4.67em;
    @media (min-width: 1024px) {
      padding-left: 1.67em;
    }
  }
  &:last-child {
    padding-right: 2.335em;
    @media (min-width: 1024px) {
      padding-right: 1em;
    }
  }
`;

import React from "react";

export default ({ width = 23, height = 23, color = "#ffa700", ...rest }) => {
  return (
    <i {...rest} style={{ cursor: "pointer", ...rest.style }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      >
        <g transform="translate(-265 -174)">
          <g
            transform="translate(265 174)"
            fill="none"
            stroke={color}
            strokeWidth="1"
          >
            <circle cx="11.5" cy="11.5" r="11.5" stroke="none" />
            <circle cx="11.5" cy="11.5" r="11" fill="none" />
          </g>
          <path
            d="M3,10.181v1.89H4.89L10.465,6.5l-1.89-1.89Zm8.926-5.146a.5.5,0,0,0,0-.711L10.747,3.145a.5.5,0,0,0-.711,0l-.922.922L11,5.957Z"
            transform="translate(269.163 177.669)"
            fill={color}
          />
        </g>
      </svg>
    </i>
  );
};

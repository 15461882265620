import React from "react";

export default ({
  width = 17.496,
  height = 28.384,
  color = "#ffa700",
  ...rest
}) => {
  return (
    <i {...rest} style={{ cursor: "pointer", ...rest.style }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      >
        <path
          d="M273.4,30.26,260.785,42.546l12.618,12.1"
          transform="translate(-257.906 -28.26)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="4"
        />
      </svg>
    </i>
  );
};

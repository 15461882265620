import styled from "styled-components";
import ArrowRight from "components/ArrowRight/ArrowRight";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2.4em 2.83em;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2em;
  & > button {
    margin-left: 0.75em;
  }
`;

export const CompanyProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CompanyProduct = styled.div`
  display: inline-flex;
  flex-direction: column;
  height: 19.75em;
  width: 36.42em;
  background: #fff;
  margin-right: 1.67em;
  margin-bottom: 1.67em;
  box-shadow: 0px 0.25em 0.5em 0px rgba(0, 0, 0, 0.2);
`;

export const ProductHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 5em;
  background: #eff5ff;
  padding-left: 2.33em;
  padding-right: 2.5em;
`;

export const ProductTitle = styled.span`
  font-size: 1.17em;
  font-weight: 600;
  margin: 0;
`;

export const ProductVersion = styled.span`
  font-size: 1.08em;
  margin: 0;
  font-weight: normal;
  color: #5f6176;
`;

export const SeeDetails = styled.b`
  font-size: 1.17em;
  margin-right: 0.83em;
  color: #ffa700;
  font-weight: 600;
`;

export const LinkContainer = styled.div`
  display: inline-flex;
`;

export const ProductBody = styled.div`
  padding: 0.33em 2.875em 2.5em 2.375em;
`;

export const ProductBodyItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 41, 56, 0.14);
  padding-bottom: 1.21em;
  padding-top: 1.34em;
`;

export const ProcessItem = styled.div`
  box-shadow: 0px 0.25em 0.5em 0px rgba(0, 0, 0, 0.2);
  min-width: 25.67em;
  margin-bottom: 2em;
`;

export const ProcessItemHeader = styled.div`
  background: #eff5ff;
  padding: 1.25em 1.45em 1.67em;
`;

export const ProcessItemTitle = styled.span`
  margin: 0;
  color: #434452;
  font-size: 1.17em;
  font-weight: 600;
`;

export const ProcessItemBody = styled.div`
  padding: 1.25em 1.45em 1.67em;
  background: white;
`;

export const AddProcessItemElement = styled.div`
  padding: 1em 1.16em 1.2em;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-bottom: 1px solid rgba(255, 167, 0, 0.3);
`;

export const AddProcessItemElementText = styled.span`
  margin: 0;
  margin-left: 1em;
  color: #ffa700;
  font-weight: 600;
  font-size: 1.16em;
`;

export const ProcessItemList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ProcessItemListElement = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 1em 1.16em 1.2em;
  border-bottom: 1px solid rgba(32, 41, 56, 0.2);
  user-select: none;
  cursor: pointer;
  color: #5f6176;
  &:hover,
  &.ProcessItemListElement-active {
    background: rgba(255, 167, 0, 0.2);
  }
`;

export const ActivityItemListElement = styled(ProcessItemListElement)`
  cursor: default;
  &:hover,
  &.ProcessItemListElement-active {
    background: none;
  }
`;
export const ProcessItemListElementText = styled.span`
  font-size: 1.08em;
  margin: 0;
  margin-right: 0.5em;
  padding: 0;
  line-height: 1.24;
`;

export const ProcessItemListElementIcon = styled.div`
  display: inline-flex;
  align-self: center;
  opacity: 0;
  flex-shrink: 0;
  ${ProcessItemListElement}:hover &,
  ${ProcessItemListElement}.ProcessItemListElement-active & {
    opacity: 1;
  }
`;

export const ActivityListIcon = styled.div`
  display: inline-flex;
  flex-shrink: 0;
`;

export const ProcessItemsContainer = styled.div`
  display: flex;
`;

export const ValuesItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ValuesStatisticsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.84em;
`;

export const ValuesStatisticsElement = styled.div`
  display: flex;
  justify-content: space-between;
  width: 49.5%;
  border-radius: 0.34em;
  background: #fff;
  box-shadow: 0px 0.25em 0.5em 0px rgba(0, 0, 0, 0.2);
  padding: 1.1em 1.3em 1.1em 1.5em;
  box-sizing: border-box;
`;

export const ValuesStatisticsElementIcon = styled(ArrowRight)`
  margin-right: 1.4em;
  display: inline-block;
`;

export const ValuesStatisticsElementText = styled.span`
  font-weight: 600;
  font-size: 1.17em;
  color: #434452;
`;

export const ValuesStatisticsElementValue = styled.div`
  background: #eff5ff;
  padding: 0.75em 1.7em;
  border-radius: 0.25em;
  flex-shrink: 0;
  height: 4em;
`;

export const ValuesStatisticsElementValueText = styled.span`
  font-size: 1.75em;
  color: #ffa700;
`;

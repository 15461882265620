import {
  LOAD_COMPANIES,
  LOAD_COMPANIES_SUCCESS,
  LOAD_COMPANIES_ERROR,
  LOAD_COMPANY,
  LOAD_COMPANY_SUCCESS,
  SET_CURRENT_COMPANY,
  CREATE_PRODUCTIVITY,
  CREATE_PRODUCTIVITY_SUCCESS,
  CREATE_PRODUCTIVITY_FAILURE,
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE
} from "./constants";

/**
 * Load the COMPANIES, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_COMPANIES
 */
export function loadCompanies() {
  return {
    type: LOAD_COMPANIES
  };
}

/**
 * Dispatched when the COMPANIES are loaded by the request saga
 *
 * @param  {array} companies The companies data
 *
 * @return {object}      An action object with a type of LOAD_COMPANIES_SUCCESS passing the repos
 */
export function companiesLoaded(companies) {
  return {
    type: LOAD_COMPANIES_SUCCESS,
    payload: { data: companies }
  };
}

/**
 * Dispatched when loading the COMPANIES fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_COMPANIES_ERROR passing the error
 */
export function companiesLoadingError(error) {
  return {
    type: LOAD_COMPANIES_ERROR,
    error
  };
}

/**
 * Load the current COMPANY, this action starts the request saga
 *
 * @return {object} An action object with a type of LOAD_COMPANIES
 */
export function loadCompany(id) {
  return {
    type: LOAD_COMPANY,
    payload: id
  };
}

/**
 * Dispatched when the COMPANY details are loaded by the request saga
 *
 * @param  {object} company The company data
 *
 * @return {object}      An action object with a type of LOAD_COMPANY_SUCCESS passing the repos
 */
export function companyLoaded(company) {
  return {
    type: LOAD_COMPANY_SUCCESS,
    payload: { company }
  };
}

/**
 * Dispatched when the COMPANY details are loaded by the request saga
 *
 * @param  {number} id The company data
 *
 * @return {object}      An action object with a type of LOAD_COMPANY_SUCCESS passing the repos
 */
export function setCurrentCompanyId(id) {
  return {
    type: SET_CURRENT_COMPANY,
    payload: { id }
  };
}

export function createProductivity(companyId, name) {
  return {
    type: CREATE_PRODUCTIVITY,
    payload: {
      companyId,
      name
    }
  };
}

export function createProductivitySuccess(data) {
  return {
    type: CREATE_PRODUCTIVITY_SUCCESS,
    payload: data
  };
}

export function createProductivityFailure(error) {
  return {
    type: CREATE_PRODUCTIVITY_FAILURE,
    error
  };
}

export function createCompany(name) {
  return {
    type: CREATE_COMPANY,
    payload: name
  };
}

export function createCompanySuccess(newCompany) {
  return {
    type: CREATE_COMPANY_SUCCESS,
    payload: newCompany
  };
}

export function createCompanyFailure(error) {
  return {
    type: CREATE_COMPANY_FAILURE,
    error
  };
}

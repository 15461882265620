export const LOAD_PRODUCT = "dashboard/Products/LOAD_PRODUCT";
export const LOAD_PRODUCT_SUCCESS = "dashboard/Products/LOAD_PRODUCT_SUCCESS";
export const LOAD_PRODUCT_FAILURE = "dashboard/Products/LOAD_PRODUCT_FAILURE";
export const LOAD_PRODUCTS = "dashboard/Products/LOAD_PRODUCTS";
export const LOAD_PRODUCTS_SUCCESS = "dashboard/Products/LOAD_PRODUCTS_SUCCESS";
export const LOAD_PRODUCTS_ERROR = "dashboard/Products/LOAD_PRODUCTS_ERROR";
export const SET_CURRENT_PRODUCT = "dashboard/Products/SET_CURRENT_PRODUCT";
export const LOAD_MACRO_PROCESSES = "dashboard/Products/LOAD_MACRO_PROCESSES";
export const LOAD_MACRO_PROCESSES_SUCCESS =
  "dashboard/Products/LOAD_MACRO_PROCESSES_SUCCESS";
export const LOAD_MACRO_PROCESSES_FAILURE =
  "dashboard/Products/LOAD_MACRO_PROCESSES_FAILURE";
export const LOAD_PROCESSES = "dashboard/Products/LOAD_PROCESSES";
export const LOAD_PROCESSES_SUCCESS =
  "dashboard/Products/LOAD_PROCESSES_SUCCESS";
export const LOAD_PROCESSES_FAILURE =
  "dashboard/Products/LOAD_PROCESSES_FAILURE";
export const LOAD_ACTIVITIES = "dashboard/Products/LOAD_ACTIVITIES";
export const LOAD_ACTIVITIES_SUCCESS =
  "dashboard/Products/LOAD_ACTIVITIES_SUCCESS";
export const LOAD_ACTIVITIES_FAILURE =
  "dashboard/Products/LOAD_ACTIVITIES_FAILURE";
export const CREATE_MACRO_PROCESS = "dashboard/Products/CREATE_MACRO_PROCESS";
export const CREATE_MACRO_PROCESS_SUCCESS =
  "dashboard/Products/CREATE_MACRO_PROCESS_SUCCESS";
export const CREATE_MACRO_PROCESS_FAILURE =
  "dashboard/Products/CREATE_MACRO_PROCESS_FAILURE";
export const CREATE_PROCESS = "dashboard/Products/CREATE_PROCESS";
export const CREATE_PROCESS_SUCCESS =
  "dashboard/Products/CREATE_PROCESS_SUCCESS";
export const CREATE_PROCESS_FAILURE =
  "dashboard/Products/CREATE_PROCESS_FAILURE";
export const CREATE_ACTIVITY = "dashboard/Products/CREATE_ACTIVITY";
export const CREATE_ACTIVITY_SUCCESS =
  "dashboard/Products/CREATE_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY_FAILURE =
  "dashboard/Products/CREATE_ACTIVITY_FAILURE";
export const UPDATE_ACTIVITY = "dashboard/Products/UPDATE_ACTIVITY";
export const UPDATE_ACTIVITY_SUCCESS =
  "dashboard/Products/UPDATE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_FAILURE =
  "dashboard/Products/UPDATE_ACTIVITY_FAILURE";

export const GET_GENERIC_POSITIONS = "dashboard/Products/GET_GENERIC_POSITIONS";
export const GET_GENERIC_POSITIONS_SUCCESS =
  "dashboard/Products/GET_GENERIC_POSITIONS_SUCCESS";
export const GET_GENERIC_POSITIONS_FAILURE =
  "dashboard/Products/GET_GENERIC_POSITIONS_FAILURE";

export const CREATE_GENERIC_POSITION =
  "dashboard/Products/CREATE_GENERIC_POSITION";
export const CREATE_GENERIC_POSITION_SUCCESS =
  "dashboard/Products/CREATE_GENERIC_POSITION_SUCCESS";
export const CREATE_GENERIC_POSITION_FAILURE =
  "dashboard/Products/CREATE_GENERIC_POSITION_FAILURE";

export const GET_MANAGEMENT_POSITIONS =
  "dashboard/Products/GET_MANAGEMENT_POSITIONS";
export const GET_MANAGEMENT_POSITIONS_SUCCESS =
  "dashboard/Products/GET_MANAGEMENT_POSITIONS_SUCCESS";
export const GET_MANAGEMENT_POSITIONS_FAILURE =
  "dashboard/Products/GET_MANAGEMENT_POSITIONS_FAILURE";

export const CREATE_MANAGEMENT_POSITION =
  "dashboard/Products/CREATE_MANAGEMENT_POSITION";
export const CREATE_MANAGEMENT_POSITION_SUCCESS =
  "dashboard/Products/CREATE_MANAGEMENT_POSITION_SUCCESS";
export const CREATE_MANAGEMENT_POSITION_FAILURE =
  "dashboard/Products/CREATE_MANAGEMENT_POSITION_FAILURE";

export const GET_WORK_POSITIONS = "dashboard/Products/GET_WORK_POSITIONS";
export const GET_WORK_POSITIONS_SUCCESS =
  "dashboard/Products/GET_WORK_POSITIONS_SUCCESS";
export const GET_WORK_POSITIONS_FAILURE =
  "dashboard/Products/GET_WORK_POSITIONS_FAILURE";

export const CREATE_WORK_POSITION = "dashboard/Products/CREATE_WORK_POSITION";
export const CREATE_WORK_POSITION_SUCCESS =
  "dashboard/Products/CREATE_WORK_POSITION_SUCCESS";
export const CREATE_WORK_POSITION_FAILURE =
  "dashboard/Products/CREATE_WORK_POSITION_FAILURE";

export const EDIT_PRODUCTIVITY = "dashboard/Products/EDIT_PRODUCTIVITY";
export const EDIT_PRODUCTIVITY_SUCCESS =
  "dashboard/Products/EDIT_PRODUCTIVITY_SUCCESS";
export const EDIT_PRODUCTIVITY_FAILURE =
  "dashboard/Products/EDIT_PRODUCTIVITY_FAILURE";

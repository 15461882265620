import React from "react";
import { Container, Name, Avatar } from "./styledComponents";
import { Link } from "react-router-dom";
import avatarImg from "assets/images/avatar.png";

export default () => (
  <Container>
    <Name>Hi!</Name>
    <Link to="/">
      <Avatar src={avatarImg} alt="jaime" />
    </Link>
  </Container>
);

import styled from "styled-components";

export const Container = styled.div`
  min-height: 8.7em;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 1.75em;
  padding-left: 3.5em;
  padding-top: 2em;
  flex-shrink: 0;
  box-shadow: 0px 0.25em 0.5em 0px rgba(0, 0, 0, 0.06);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Sections = styled.div`
  display: flex;
`;

export const SectionsList = styled.ul`
  list-style: none;
  margin: 0;
`;

export const SectionsListItem = styled.li`
  margin: 0;
  font-size: 1.08em;
  padding: 1em 2em;
  color: #434452;
  display: inline-block;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition-duration: 0.2s;
  &:hover,
  &.sectionsListItem-active {
    color: #ffa700;
    border-bottom-color: #ffa700;
    font-weight: 600;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  margin: 0;
  font-size: 1.33em;
  display: inline-block;
  color: #434452;
  font-weight: 500;
  margin-top: 0.35em;
  margin-left: 2em;
`;

export const Name = styled.span`
  margin: 0;
  margin-right: 1.25em;
  font-size: 1.17em;
  display: inline-block;
  color: #434452;
  font-weight: 500;
`;

export const Avatar = styled.img`
  margin: 0;
  height: 2.93em;
  width: 2.93em;
  display: inline-block;
`;

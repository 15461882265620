import {
  SET_CURRENT_PRODUCT,
  LOAD_PRODUCT_SUCCESS,
  LOAD_PRODUCTS_SUCCESS,
  LOAD_MACRO_PROCESSES_SUCCESS,
  LOAD_PROCESSES_SUCCESS,
  LOAD_ACTIVITIES_SUCCESS,
  CREATE_MACRO_PROCESS_SUCCESS,
  CREATE_PROCESS_SUCCESS,
  CREATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_SUCCESS,
  GET_GENERIC_POSITIONS_SUCCESS,
  CREATE_GENERIC_POSITION_SUCCESS,
  GET_MANAGEMENT_POSITIONS_SUCCESS,
  CREATE_MANAGEMENT_POSITION_SUCCESS,
  GET_WORK_POSITIONS_SUCCESS,
  CREATE_WORK_POSITION_SUCCESS,
  EDIT_PRODUCTIVITY_SUCCESS
} from "./constants";
import produce from "immer";

// The initial state of the App
export const initialState = {
  data: {},
  current: undefined,
  macros: {},
  processes: {},
  activities: {},
  generic_positions: {},
  managements: {},
  work_positions: {}
};

const appReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case SET_CURRENT_PRODUCT:
        draft.current = Number(payload.id);
        break;
      case LOAD_PRODUCTS_SUCCESS:
        draft.data = payload.data.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
        break;
      case LOAD_PRODUCT_SUCCESS:
      case EDIT_PRODUCTIVITY_SUCCESS:
        draft.data[payload.id] = payload;
        break;
      case LOAD_MACRO_PROCESSES_SUCCESS:
        const { macros } = payload;
        draft.macros = macros.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
        break;
      case CREATE_MACRO_PROCESS_SUCCESS:
        draft.macros[payload.id] = payload;
        break;
      case LOAD_PROCESSES_SUCCESS:
        draft.processes = payload.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
        break;
      case CREATE_PROCESS_SUCCESS:
        draft.processes[payload.id] = payload;
        break;
      case LOAD_ACTIVITIES_SUCCESS:
        draft.activities = payload.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
        break;
      case CREATE_ACTIVITY_SUCCESS:
      case UPDATE_ACTIVITY_SUCCESS:
        draft.activities[payload.id] = payload;
        break;
      case GET_GENERIC_POSITIONS_SUCCESS:
        draft.generic_positions = payload.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
        break;
      case CREATE_GENERIC_POSITION_SUCCESS:
        draft.generic_positions[payload.id] = payload;
        break;
      case GET_MANAGEMENT_POSITIONS_SUCCESS:
        draft.managements = payload.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
        break;
      case CREATE_MANAGEMENT_POSITION_SUCCESS:
        draft.managements[payload.id] = payload;
        break;
      case GET_WORK_POSITIONS_SUCCESS:
        draft.work_positions = payload.reduce((obj, item) => {
          obj[item.id] = item;
          return obj;
        }, {});
        break;
      case CREATE_WORK_POSITION_SUCCESS:
        draft.work_positions[payload.id] = payload;
        break;
      default:
        break;
    }
  });

export default appReducer;

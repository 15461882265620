import React, { Fragment } from "react";
import {
  ActionsContainer,
  ValuesStatisticsContainer,
  ValuesStatisticsElement,
  ValuesStatisticsElementText,
  ValuesStatisticsElementIcon,
  ValuesStatisticsElementValue,
  ValuesStatisticsElementValueText,
  ValuesItemsContainer
} from "./styledComponents";
import Button from "components/Button/Button";
import JobPositionsTree from "components/JobPositionsTree";

function DefaultValuesTab({
  working_hours,
  unproductive_percent,
  newWorkPosition
}) {
  return (
    <Fragment>
      <ActionsContainer>
        <Button>Cargar Excel</Button>
      </ActionsContainer>
      <ValuesStatisticsContainer>
        <ValuesStatisticsElement>
          <ValuesStatisticsElementText>
            <ValuesStatisticsElementIcon />
            Horas que se deben de trabajar reportadas por la empresa
          </ValuesStatisticsElementText>
          <ValuesStatisticsElementValue>
            <ValuesStatisticsElementValueText>
              {working_hours}
            </ValuesStatisticsElementValueText>
          </ValuesStatisticsElementValue>
        </ValuesStatisticsElement>
        <ValuesStatisticsElement>
          <ValuesStatisticsElementText>
            <ValuesStatisticsElementIcon />% de horas improductivas
          </ValuesStatisticsElementText>
          <ValuesStatisticsElementValue>
            <ValuesStatisticsElementValueText>
              {unproductive_percent}
            </ValuesStatisticsElementValueText>
          </ValuesStatisticsElementValue>
        </ValuesStatisticsElement>
      </ValuesStatisticsContainer>
      <ValuesItemsContainer>
        <JobPositionsTree />
      </ValuesItemsContainer>
    </Fragment>
  );
}

export default DefaultValuesTab;

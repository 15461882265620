import React, { Fragment } from "react";
import { TableContainer, THead, TBody, TD } from "./styledComponents";
import map from "lodash/map";
import get from "lodash/get";

const columns = [
  { header: "Nombre", name: "name" },
  { header: "Nombre de contacto", name: "contact" },
  { header: "Mail de encargado", name: "email" },
  { header: "Productos", name: "products" },
  { header: "Acciones", name: "actions" }
];

export function TableComponent({ cols = columns, data, onClickItem, ...rest }) {
  return (
    <TableContainer>
      <table {...rest}>
        <THead>
          <tr>
            {cols.map(col => (
              <th key={col.name}>{col.header}</th>
            ))}
          </tr>
        </THead>
        <TBody>
          {map(data, row => (
            <tr key={row.id} onClick={() => onClickItem(row.id)}>
              {cols.map(col => (
                <TD key={col.name}>
                  {col.name === "products"
                    ? [
                        ...get(row, "productivities", []),
                        ...get(row, "strategies", [])
                      ].map(i => (
                        <Fragment key={`${i.id}-${i.name}`}>
                          {i.name}
                          <br />
                        </Fragment>
                      ))
                    : row[col.name]}
                </TD>
              ))}
            </tr>
          ))}
        </TBody>
      </table>
    </TableContainer>
  );
}

export default TableComponent;

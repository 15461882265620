import React, { useState, useEffect } from "react";
import { SkyLightStateless } from "react-skylight";
import {
  ModalFormGroup,
  ModalLabel,
  ModalInput,
  ModalSubmit
} from "./styledComponents";
import Select from "react-select";
import get from "lodash/get";

const options = [
  { value: "Planear", label: "Planear" },
  { value: "Hacer", label: "Hacer" },
  { value: "Verificar", label: "Verificar" },
  { value: "Actuar", label: "Actuar" }
];

const modalStyles = {
  dialog: {
    border: "1px solid #707070",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  closeButton: {
    color: "#FFA700",
    right: 20,
    top: 10,
    fontSize: "3.5em"
  },
  title: {
    fontSize: "1.67em",
    marginTop: "2.5em",
    marginBottom: "3em",
    color: "#434452",
    fontWeight: "bold"
  }
};

function NewItemModal({
  isVisible,
  toggleModal,
  onSubmit,
  submitText = "",
  title = "",
  activity = {}
}) {
  const [description, setDescription] = useState(
    get(activity, "description", "")
  );
  const [action, setAction] = useState({
    value: get(activity, "action", ""),
    label: get(activity, "action", "")
  });
  const updateValue = ({ target }) => setDescription(target.value);
  useEffect(() => {
    if (!isVisible && description) {
      setDescription("");
    }
    if (!isVisible && action) {
      setAction({ value: "", label: "" });
    }
  }, [setDescription, isVisible, description, action]);
  useEffect(() => {
    if (activity.id) {
      setDescription(activity.description);
      setAction({ value: activity.action, label: activity.action });
    }
  }, [activity]);
  const handleChangeAction = selectedOption => {
    setAction(selectedOption);
  };
  const handleSubmit = () => {
    if (description && description.trim()) {
      onSubmit && onSubmit(description, action.value);
    }
  };
  return (
    <SkyLightStateless
      isVisible={isVisible}
      dialogStyles={modalStyles.dialog}
      closeButtonStyle={modalStyles.closeButton}
      titleStyle={modalStyles.title}
      title={title}
      onOverlayClicked={toggleModal}
      onCloseClicked={toggleModal}
    >
      <ModalFormGroup>
        <ModalLabel>Description</ModalLabel>
        <ModalInput
          value={description}
          onChange={updateValue}
          autoFocus={true}
        />
      </ModalFormGroup>
      <ModalFormGroup>
        <ModalLabel>Acción</ModalLabel>
        <Select
          value={action}
          onChange={handleChangeAction}
          options={options}
          placeholder="Seleccionar acción ..."
          theme={theme => ({
            ...theme,
            borderRadius: "0.357em",
            colors: {
              ...theme.colors,
              primary: "#ffa700"
            }
          })}
        />
        <ModalSubmit onClick={handleSubmit}>{submitText}</ModalSubmit>
      </ModalFormGroup>
    </SkyLightStateless>
  );
}

export default NewItemModal;

import React from "react";
import {
  Container,
  Heading,
  Title,
  NavList,
  NavListItem,
  NavListItemText
} from "./styledComponents";
import { NavLink, Link } from "react-router-dom";
import HomeIcon from "./HomeIcon";
import CompaniesIcon from "./CompaniesIcon";
import ProductsIcon from "./ProductsIcon";
import Logo from "components/Logo/Logo";

export default () => (
  <Container>
    <Link to="/">
      <Heading>
        <Logo />
        <Title>TRACKING</Title>
      </Heading>
    </Link>
    <NavList>
      <NavLink to="/" exact>
        <NavListItem>
          <HomeIcon />
          <NavListItemText>Home</NavListItemText>
        </NavListItem>
      </NavLink>
      <NavLink to="/companies">
        <NavListItem>
          <CompaniesIcon />
          <NavListItemText>Companies</NavListItemText>
        </NavListItem>
      </NavLink>
      <NavLink to="/products">
        <NavListItem>
          <ProductsIcon />
          <NavListItemText>Products</NavListItemText>
        </NavListItem>
      </NavLink>
    </NavList>
  </Container>
);

import React from "react";

export default ({ width = 36, height = 36, color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <circle cx="18" cy="18" r="18" fill={color} opacity="0.485" />
    </svg>
  );
};

import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOAD_PRODUCTS,
  LOAD_PRODUCT,
  LOAD_MACRO_PROCESSES,
  LOAD_PROCESSES,
  LOAD_ACTIVITIES,
  CREATE_MACRO_PROCESS,
  CREATE_ACTIVITY,
  CREATE_PROCESS,
  UPDATE_ACTIVITY,
  GET_GENERIC_POSITIONS,
  CREATE_GENERIC_POSITION,
  GET_MANAGEMENT_POSITIONS,
  CREATE_MANAGEMENT_POSITION,
  GET_WORK_POSITIONS,
  CREATE_WORK_POSITION,
  EDIT_PRODUCTIVITY
} from "./constants";
import {
  getProductSuccess,
  getProductsSuccess,
  getProductsFailure,
  getMacroProcessesSuccess,
  getProcessesSuccess,
  getActivitiesSuccess,
  getMacroProcessesFailure,
  getProcessesFailure,
  getActivitiesFailure,
  createMacroProcessSuccess,
  createMacroProcessFailure,
  createProcessSuccess,
  createProcessFailure,
  createActivitySuccess,
  createActivityFailure,
  updateActivitySuccess,
  updateActivityFailure,
  fetchGenericPositionsSuccess,
  fetchGenericPositionsFailure,
  createGenericPositionSuccess,
  createGenericPositionFailure,
  fetchManagementPositionsSuccess,
  fetchManagementPositionsFailure,
  createManagementPositionSuccess,
  createManagementPositionFailure,
  fetchWorkPositionsSuccess,
  fetchWorkPositionsFailure,
  createWorkPositionSuccess,
  createWorkPositionFailure,
  editProductivitySuccess,
  editProductivityFailure
} from "containers/Products/actions";
import Axios from "utils/axios";
import { push } from "connected-react-router";

const getProductsRequest = () => {
  const baseUrl = "/products";

  return Axios.get(baseUrl)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

const getProductRequest = id => {
  return Axios.get(`/productivity/${id}`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

const getMacrosRequest = productivity_id => {
  return Axios.get(`/productivity/${productivity_id}/macro-processes`)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

const createMacroRequest = (productivity_id, description) => {
  return Axios.post(`/productivity/${productivity_id}/macro-processes`, {
    description
  })
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

const getProcessesRequest = (productivity_id, macro_process_id) => {
  return Axios.get(`/productivity/${productivity_id}/processes`, {
    params: { macro_process_id }
  })
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

const createProcessRequest = (
  productivity_id,
  description,
  macro_process_id
) => {
  return Axios.post(`/productivity/${productivity_id}/processes`, {
    description,
    macro_process_id
  })
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

const getActivitiesRequest = (
  productivity_id,
  macro_process_id,
  process_id
) => {
  return Axios.get(`/productivity/${productivity_id}/activities`, {
    params: { macro_process_id, process_id }
  })
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

const createActivityRequest = (
  productivity_id,
  description,
  process_id,
  action
) => {
  return Axios.post(`/productivity/${productivity_id}/activities`, {
    description,
    process_id,
    action
  })
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

const updateActivityRequest = (
  activity_id,
  productivity_id,
  description,
  action
) => {
  return Axios.put(
    `/productivity/${productivity_id}/activities/${activity_id}`,
    {
      description,
      action
    }
  )
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

export function* getProductsData() {
  try {
    const data = yield call(getProductsRequest);
    yield put(getProductsSuccess(data));
  } catch (err) {
    yield put(getProductsFailure(err));
  }
}

export function* getProductData(action) {
  try {
    const data = yield call(getProductRequest, action.payload);
    yield put(getProductSuccess(data));
  } catch (err) {
    yield put(push("/404"));
  }
}

export function* getMacroProcessesData(action) {
  try {
    const { productivity_id } = action.payload;
    const data = yield call(getMacrosRequest, productivity_id);
    yield put(getMacroProcessesSuccess(productivity_id, data));
  } catch (err) {
    yield put(getMacroProcessesFailure(err));
  }
}

export function* createMacroProcess(action) {
  try {
    const { productivity_id, description } = action.payload;
    const data = yield call(createMacroRequest, productivity_id, description);
    yield put(createMacroProcessSuccess(data));
  } catch (err) {
    yield put(createMacroProcessFailure(err));
  }
}
export function* getProcessesData(action) {
  try {
    const { productivity_id, macro_id } = action.payload;
    const data = yield call(
      getProcessesRequest,
      Number(productivity_id),
      macro_id
    );
    yield put(getProcessesSuccess(data));
  } catch (err) {
    yield put(getProcessesFailure(err));
  }
}

export function* createProcess(action) {
  try {
    const { productivity_id, description, macro_id } = action.payload;
    const data = yield call(
      createProcessRequest,
      productivity_id,
      description,
      macro_id
    );
    yield put(createProcessSuccess(data));
  } catch (err) {
    yield put(createProcessFailure(err));
  }
}

export function* getActivitiesData(action) {
  try {
    const data = yield call(getActivitiesRequest, action.payload);
    yield put(getActivitiesSuccess(data));
  } catch (err) {
    yield put(getActivitiesFailure(err));
  }
}

export function* createActivity(action) {
  try {
    const {
      productivity_id,
      description,
      process_id,
      activity_action
    } = action.payload;
    const data = yield call(
      createActivityRequest,
      productivity_id,
      description,
      process_id,
      activity_action
    );
    yield put(createActivitySuccess(data));
  } catch (err) {
    yield put(createActivityFailure(err));
  }
}

export function* updateActivity(action) {
  try {
    const {
      activity_id,
      productivity_id,
      description,
      activity_action
    } = action.payload;
    const data = yield call(
      updateActivityRequest,
      activity_id,
      productivity_id,
      description,
      activity_action
    );
    yield put(updateActivitySuccess(data));
  } catch (err) {
    yield put(updateActivityFailure(err));
  }
}

// Positions

const getGenericPositionRequest = productivity_id => {
  return Axios.get(`/productivity/${productivity_id}/generic-positions`)
    .then(res => res.data.map(i => ({ ...i, productivity_id })))
    .catch(error => {
      throw error;
    });
};

const createGenericPositionRequest = (productivity_id, name) => {
  return Axios.post(`/productivity/${productivity_id}/generic-positions`, {
    name
  })
    .then(res => ({ ...res.data, productivity_id }))
    .catch(error => {
      throw error;
    });
};

export function* createGenericPosition(action) {
  try {
    const { productivity_id, name } = action.payload;
    const data = yield call(
      createGenericPositionRequest,
      productivity_id,
      name
    );
    yield put(createGenericPositionSuccess(data));
  } catch (err) {
    yield put(createGenericPositionFailure(err));
  }
}

export function* getGenericPosition(action) {
  try {
    const data = yield call(getGenericPositionRequest, action.payload);
    yield put(fetchGenericPositionsSuccess(data));
  } catch (err) {
    yield put(fetchGenericPositionsFailure(err));
  }
}

const getManagementPositionRequest = productivity_id => {
  return Axios.get(`/productivity/${productivity_id}/management-positions`)
    .then(res => res.data.map(i => ({ ...i, productivity_id })))
    .catch(error => {
      throw error;
    });
};

const createManagementPositionRequest = (productivity_id, name) => {
  return Axios.post(`/productivity/${productivity_id}/management-positions`, {
    name
  })
    .then(res => ({ ...res.data, productivity_id }))
    .catch(error => {
      throw error;
    });
};

export function* createManagementPosition(action) {
  try {
    const { productivity_id, name } = action.payload;
    const data = yield call(
      createManagementPositionRequest,
      productivity_id,
      name
    );
    yield put(createManagementPositionSuccess(data));
  } catch (err) {
    yield put(createManagementPositionFailure(err));
  }
}

export function* getManagementPosition(action) {
  try {
    const data = yield call(getManagementPositionRequest, action.payload);
    yield put(fetchManagementPositionsSuccess(data));
  } catch (err) {
    yield put(fetchManagementPositionsFailure(err));
  }
}

const getWorkPositionRequest = productivity_id => {
  return Axios.get(`/productivity/${productivity_id}/work-positions`)
    .then(res => res.data.map(i => ({ ...i, productivity_id })))
    .catch(error => {
      throw error;
    });
};

const createWorkPositionRequest = (productivity_id, name) => {
  return Axios.post(`/productivity/${productivity_id}/work-positions`, {
    name
  })
    .then(res => ({ ...res.data, productivity_id }))
    .catch(error => {
      throw error;
    });
};

const editProductivityRequest = (productivity_id, data) => {
  return Axios.put(`/productivity/${productivity_id}`, {
    ...data
  })
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};

export function* createWorkPosition(action) {
  try {
    const { productivity_id, name } = action.payload;
    const data = yield call(createWorkPositionRequest, productivity_id, name);
    yield put(createWorkPositionSuccess(data));
  } catch (err) {
    yield put(createWorkPositionFailure(err));
  }
}

export function* getWorkPosition(action) {
  try {
    const data = yield call(getWorkPositionRequest, action.payload);
    yield put(fetchWorkPositionsSuccess(data));
  } catch (err) {
    yield put(fetchWorkPositionsFailure(err));
  }
}

export function* editProductivity(action) {
  try {
    const { productivity_id, data: info } = action.payload;
    const data = yield call(editProductivityRequest, productivity_id, info);
    yield put(editProductivitySuccess(data));
  } catch (err) {
    yield put(editProductivityFailure(err));
  }
}

export default function* productsData() {
  yield takeLatest(LOAD_PRODUCTS, getProductsData);
  yield takeLatest(LOAD_PRODUCT, getProductData);
  yield takeLatest(LOAD_MACRO_PROCESSES, getMacroProcessesData);
  yield takeLatest(LOAD_PROCESSES, getProcessesData);
  yield takeLatest(LOAD_ACTIVITIES, getActivitiesData);
  yield takeLatest(CREATE_MACRO_PROCESS, createMacroProcess);
  yield takeLatest(CREATE_PROCESS, createProcess);
  yield takeLatest(CREATE_ACTIVITY, createActivity);
  yield takeLatest(UPDATE_ACTIVITY, updateActivity);
  yield takeLatest(GET_GENERIC_POSITIONS, getGenericPosition);
  yield takeLatest(CREATE_GENERIC_POSITION, createGenericPosition);
  yield takeLatest(GET_MANAGEMENT_POSITIONS, getManagementPosition);
  yield takeLatest(CREATE_MANAGEMENT_POSITION, createManagementPosition);
  yield takeLatest(GET_WORK_POSITIONS, getWorkPosition);
  yield takeLatest(CREATE_WORK_POSITION, createWorkPosition);
  yield takeLatest(EDIT_PRODUCTIVITY, editProductivity);
}

import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import { Card, Title } from "rbx";

const data = [
  {
    name: "Asesor",
    reported: 45
  },
  {
    name: "Profesional Junior",
    reported: 49
  },
  {
    name: "Lider Urbes",
    reported: 50
  },
  {
    name: "Lider Generación Térmica e Industria",
    reported: 49
  },
  {
    name: "Asesor Gerencia de Desarrollo de Negocios",
    reported: 48
  },
  {
    name: "Profesional Senior",
    reported: 52
  },
  {
    name: "Profesional especialista",
    reported: 55
  }
];

function LineChartReport() {
  return (
    <Card>
      <Card.Content>
        <Title subtitle>Horas Reportadas</Title>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="reported" stroke="#202938" />
        </LineChart>
      </Card.Content>
    </Card>
  );
}

export default LineChartReport;

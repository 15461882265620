import { createSelector } from "reselect";
import { initialState } from "./reducer";
import get from "lodash/get";

const selectCompanies = state => state.companies || initialState;
const selectCurrentId = createSelector(
  selectCompanies,
  companies => {
    return companies.current;
  }
);

const makeSelectCompaniesData = () =>
  createSelector(
    selectCompanies,
    companiesState => companiesState.data
  );

const makeSelectCurrentCompany = () =>
  createSelector(
    makeSelectCompaniesData(),
    selectCurrentId,
    (companies, currentId) => {
      return get(companies, currentId, {});
    }
  );

export { selectCompanies, makeSelectCompaniesData, makeSelectCurrentCompany };
